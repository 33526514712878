const reload = () => {
  window.location.reload()
}

const errorMessage = (status) => {
  switch (status) {
    case 401:
      return (
        <>
          <h1 className="pds-dialog-title" id="error-dialog-title">
            Who is this?
          </h1>
          <p id="error-dialog-desc">
            You’ve been signed out of Harvest on this computer. You’ll need to
            sign in and try that last action again.
          </p>
          <button className="pds-button" onClick={reload}>
            Sign in
          </button>
        </>
      )

    default:
      return (
        <>
          <h1 className="pds-dialog-title" id="error-dialog-title">
            Houston, we’ve had a problem
          </h1>
          <p id="error-dialog-desc">
            Sorry, but we’re having trouble connecting to Harvest. This problem
            is usually the result of a broken Internet connection. You can try
            refreshing this page.
          </p>
          <button className="pds-button" onClick={reload}>
            Reload page
          </button>
        </>
      )
  }
}

export default ({ status }) => {
  return (
    <div
      className="pds-dialog pds-dialog-sm"
      role="alertdialog"
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-desc">
      {errorMessage(status)}
    </div>
  )
}
